@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@700&family=Montserrat:wght@400;700&display=swap');

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.center-img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 350px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fa8f21;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fa8f21;
}

.loading {
  position: fixed;
  z-index: 9999999;
  background-color: #fa8f21;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

html {
  scroll-behavior: smooth;
}

.remove-border:focus {
  outline-width: 0;
}

.max-width {
  max-width: 1800px;
}

.glass-effect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(9.5px);
  border-radius: 80px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  height: auto;
  max-width: 1800px;
  width: 100%;
}

@-moz-document url-prefix() {
  .glass-effect {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(9.5px);
    border-radius: 80px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: 100%;
    width: 100%;
  }
}

.dots-row {
  display: flex !important;
  justify-content: center;
  column-gap: 2rem;
}

.dots-row li button {
  border: none;
  color: transparent;
  cursor: pointer;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}
.dots-row .slick-active {
  width: 1.438rem;
  height: 1.438rem;
  background-color: #ff5b00;
  border-radius: 50%;
  opacity: 0.5;
}

.dots-row li {
  width: 1.438rem;
  height: 1.438rem;
  background-color: #ffffff;
  border-radius: 50%;
  margin-top: 2rem;
  opacity: 0.5;
}

.dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.dots li button {
  border: none;
  color: transparent;
  cursor: pointer;
  height: 15px;
  width: 15px;
  border-radius: 7.5px;
}
.dots .slick-active {
  width: 1.438rem;
  height: 1.438rem;
  background-color: #ff5b00;
  border-radius: 50%;
  opacity: 0.5;
}

.dots li {
  width: 1.438rem;
  height: 1.438rem;
  background-color: #ffffff;
  border-radius: 50%;
  margin-top: 2rem;
  opacity: 0.5;
}

.br-content {
  display: block;
}

.box-dots {
  display: flex !important;
  justify-content: center;
  margin-top: 1rem;
}

.box-dots li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.box-dots li button {
  border: none;
  cursor: pointer;
  display: block;
  width: 3.625rem;
  height: 0.313rem;
  background-color: #fff;
  opacity: 0.5;
  margin-right: 1rem;
  color: transparent;
  border-radius: 1.875rem;
}

.box-dots .slick-active {
  border: none;
  cursor: pointer;
  display: block;
  width: 3.625rem;
  height: 0.313rem;
  background-color: #fa8f21;
  opacity: 0.5;
  margin-right: 1rem;
  color: transparent;
  border-radius: 1.875rem;
}

.play-btn {
  /* margin: 0; */
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.city-search {
  height: 2.5rem;
  border-radius: 0.5rem;
}

.css-2b097c-container .css-yk16xz-control {
  height: 2.5rem;
  border-radius: 0.5rem;
}

.css-2b097c-container .css-yk16xz-control .css-g1d714-ValueContainer {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.css-2b097c-container .css-1pahdxg-control {
  height: 2.5rem;
  border-radius: 0.5rem;
}

.css-2b097c-container .css-2613qy-menu {
  background-color: #3f3f3f;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px gray inset;
}

input[type='radio']:hover {
  box-shadow: 0 0 5px 0px orange inset;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: #fa8f21;
}

.roBlack input:read-only {
  background-color: #3f3f3f;
}

.pointer {
  position: sticky;
  z-index: 99999;
}

.bx-sh {
  box-shadow: 9px 9px 9px 9px rgba(0, 0, 0, 0.25);
}

.nav-sh {
  box-shadow: -2px 7px 57px -38px rgba(242, 233, 233, 0.97);
  -webkit-box-shadow: -2px 7px 57px -38px rgba(242, 233, 233, 0.97);
  -moz-box-shadow: -2px 7px 57px -38px rgba(242, 233, 233, 0.97);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.slick-dots li button:before {
  color: #ffffff !important;
}
.slick-dots li.slick-active button:before {
  color: #ffffff !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fadeIn {
  animation: fadeIn 1s;
}
.nav-mobile {
  display: none;
}
@media only screen and (max-width: 954px) {
  .nav-web {
    display: none;
  }
  .nav-mobile {
    display: block;
  }
  br {
    display: none;
  }

  .dots {
    display: none;
  }

  .dots li {
    display: none;
  }
}

.play-btn {
  /* margin: 0; */
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 640px) {
  p {
    font-size: 12px;
  }
  .glass-effect {
    margin: 70px 0px;
  }
  .nav-mobile {
    display: block;
  }
  .nav-web {
    display: none;
  }
  .br-content {
    display: none;
  }

  .dots {
    display: none;
  }

  .dots li {
    display: none;
  }

  .box-dots {
    display: flex !important;
    justify-content: center;
    margin-top: 1rem;
  }

  .box-dots li button {
    border: none;
    cursor: pointer;
    display: block;
    width: 2rem;
    height: 0.313rem;
    background-color: #fff;
    opacity: 0.5;
    margin-right: 1rem;
    color: transparent;
    border-radius: 1.875rem;
  }

  .box-dots .slick-active {
    border: none;
    cursor: pointer;
    display: block;
    width: 2rem;
    height: 0.313rem;
    background-color: #fa8f21;
    opacity: 0.5;
    margin-right: 1rem;
    color: transparent;
    border-radius: 1.875rem;
  }
}

@media only screen and (max-width: 500px) {
  .nav-web {
    display: none;
  }

  .play-btn {
    /* margin: 0; */
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-heigth: 1200px) {
  .nav-web {
    display: none;
  }

  .play-btn {
    /* margin: 0; */
    position: absolute;
    top: 62%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 1900px) {
  .center-content {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 600px) {
  .glass-effect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(9.5px);
    border-radius: 40px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    height: auto;
    max-width: 1800px;
    width: 100%;
  }
}